import React from 'react';
import ss from "./footer.module.css";

const Footer = props => {
  return (
    <footer className={`${ss.footer} container `}>
      <h2 className={ss.header}><span>This idea is great,</span> <span>because it's mine.</span></h2>
      <p className={ss.text}><span role="img" aria-label="Look up!">👆</span>Typical Startup founder mind trap</p>
    </footer>
  );
};

export default Footer;