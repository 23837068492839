import React from 'react';
import ss from "./body.module.css"

const Body = props => {
  return (
    <section className={`${ss.container}`}>
      <div className={ss.rightColumn}>
        <article  className={ss.bodyItem}>
          <header>
            <span className={ss.iconWrap} aria-label="Flame" role="img">🔥</span>
            <h3>Get 40 pages by sharing</h3>
          </header>
          <p>Read about my biggest failure (-$100K) and what I learned from them, including in-depth analytics and data from the process. Just click the button below and share: </p>
          <a className={ss.buttonShare} href="http://www.paywithapost.de/pay?id=8600d1f5-328f-4913-88f6-95fd0cc01322" target="_blank" rel="noreferrer">Share do Download</a>
        </article>
        <article  className={ss.bodyItem}>
          <header>
            <span className={ss.iconWrap} aria-label="Rocket" role="img">🚀</span>
            <h3>What</h3>
          </header>
          <p>From pitching, market research, all the way to a polished MVP.  I will share my own successes and failures (including why they failed)  and knowledge from other successful founders I interviewed especially for this book. </p>
        </article>
        <article  className={ss.bodyItem}>
          <header>
            <span className={ss.iconWrap} aria-label="Michal" role="img">👨🏻‍💻</span>
            <h3>Who</h3>
          </header>
          <p>My name is <strong>Michal Malewicz</strong> and I've been working both with and on startups for over a 20 years. At my agency - <a target="_blank" rel="noreferrer" href="https://hype4.com"><strong>HYPE4</strong></a> we've helped <strong>deliver the design</strong>, coding and marketing for various small businesses in the past, but also, most importantly  I love starting new things myself.  I also wrote a very popular book called <a target="_blank" rel="noreferrer" href="https://designingui.com/"><strong>Designing User Interfaces</strong></a>.</p>
        </article>
      </div>
    </section>
  );
};

export default Body;