import React, {useEffect, useState} from 'react';
import ss from "./header.module.css"
import { Link } from 'gatsby';

const Header = props => {

  return (
    <>
    <header className="container">
      <div className={ss.headerWrap}>
        <p className={ss.text}>
          <span style={{margin: "0 2px 0 0"}}>Follow me on: </span>
          <a target="_blank" rel="noreferrer" className={ss.link} href="https://twitter.com/michalmalewicz">Twitter</a>
          <span className={ss.slashSpan}>/</span>
          <a target="_blank" rel="noreferrer" className={ss.link} href="https://medium.com/@michalmalewicz">Medium</a>
          <span className={ss.slashSpan}>/</span>
          <a target="_blank" rel="noreferrer" className={ss.link} href="https://www.youtube.com/c/MalewiczHype/">Youtube</a>
        </p>
      </div>
    </header>
    </>
  );
};

export default Header;