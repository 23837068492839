import React from 'react';
import ss from "./form.module.css";

import ARROW from "./chevron.svg"

const ArrowIcon = () => <img style={{margin: "0 0 0 6px"}} src={ARROW} alt="Click!" />

const Form = props => {

  return (
    <form action="https://newsletter.hype4.com/subscribe" method="POST" acceptCharset="utf-8">
      <div className={ss.inputWrap}>
        <input type="email" name="email" id="email"/>
        <button type="sumbit">Sign Up <ArrowIcon/></button>
      </div>
 
      <label className={`${ss.control} ${ss.controlCheckbox}`}>
          <p>I agree to have my email stored according to GDPR and our <a href="/policy">Privacy Policy</a></p>
          <input defaultChecked={true} type="checkbox" name="gdpr" id="gdpr"/>
        <div className={ss.control_indicator}></div>
      </label>

      <div style={{display: "none"}}>
        <label htmlFor="hp">HP</label><br/>
        <input type="text" name="hp" id="hp"/>
      </div>
      <input type="hidden" name="list" value="YkriiJ1u763SuuWrLyJtGeYg"/>
      <input type="hidden" name="subform" value="yes"/>
    </form>
  );
};

export default Form;