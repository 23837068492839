import React, {useEffect} from 'react';
import ss from "./hero.module.css";
import Form from "./form"
import Body from "../body";
import Footer from "../footer";

import BOOK from "./bg2.png"
import TEXT from "./text.png"


import * as THREE from "three";
import frag from "./shader.frag";
import vert from "./shader.vert";

const Hero = props => {


  useEffect(() => {
    
    
    setTimeout(() => {
      var canvas = document.getElementById("gradient");
    var scene = new THREE.Scene();
    var renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true });

    var camera = new THREE.PerspectiveCamera(
      45,
      canvas.clientWidth / canvas.clientWidth,
      1,
      1000,
    );

    var uniforms = {
      time: { type: "f", value: 1.0 },
      aspect: { type: "f", value: 1.0 },
    };

    var quad = new THREE.Mesh(
      new THREE.PlaneGeometry(2, 2),
      new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: vert,
        fragmentShader: frag,
        depthWrite: false,
        depthTest: false,
      }),
    );

    scene.add(quad);

    camera.position.z = 200;

    var startTime = Date.now();

    function renderAnimation() {

      requestAnimationFrame(renderAnimation);

      if (canvas.width !== canvas.clientWidth || canvas.height !== canvas.clientHeight) {
        const aspect = canvas.clientWidth / canvas.clientHeight;
        renderer.setSize(canvas.clientWidth, canvas.clientHeight, false);
        camera.aspect = aspect;

        camera.updateProjectionMatrix();
        uniforms.aspect.value = aspect;
      }

      var elapsedMilliseconds = Date.now() - startTime;
      var elapsedSeconds = elapsedMilliseconds / 1000;
      uniforms.time.value = elapsedSeconds * 0.8;

      renderer.render(scene, camera);
    }

    renderAnimation();
    }, 100)

  }, [])

  return (
    <div className={ss.outer}>
      <div className={ss.bookWrap}>
       <div className={ss.bookFixed}>
          <img className={ss.bookImg} src={BOOK} alt="That Startup Book cover"/>
          <img className={ss.bookText} src={TEXT} alt="That Startup Book cover"/>
          <canvas id="gradient" style={{width: `calc(100% - 11px)`, height: `calc(100% - 2px)`}}  className={ss.canvas}/>
       </div>
      </div>

      <div className={ss.textWrap}>
        <div className={ss.nameWrap}>
          <div></div>
          <p><strong>Michal Malewicz</strong></p>
        </div>
        <h1 className={ss.header}><strong>That Startup Book</strong></h1>
        <h2 className={ss.headerMid}>The practical, no-bullshit guide to building a <strong>successful digital business</strong></h2>
        <h3 className={ss.headerMinor}>Sign up to receive <strong>TWO CHAPTERS for free</strong> and a 30% discount when we launch 🤯😎</h3>
        <Form />
        <Body />
        <Footer />
      </div>

    </div>
  );
};

export default Hero;